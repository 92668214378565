.post-hero {
  @extend %region-spacing;
  padding-top: $spacing-2;
  background: #fff;
  position: relative;
  z-index: 2; /* to go above owl carousel */
  &:before {
    $size: 108px;
    content: '';
    position: absolute;
    background-image: url(../img/angles2.svg);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center bottom;
    height: $size;
    width: 100%;
    top: -$size;
    left: 0;
  }
}
