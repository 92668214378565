
.container {
  @mixin float-clear;
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
}

@mixin media-break $br-md {
  .container--footer {
    border-bottom: 1px dashed $text-gray;
  }
}

.main-body-content {
  background: #fff;
  @mixin media-break $br-md-2 {
    min-height: 500px;
  }
}

/* Flex Row */
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-row--neg-space-1 {
  margin-left: -$spacing-1;
  margin-right: -$spacing-1;
}

.flex-row--footer {
  justify-content: center;
  align-items: center;
}


@mixin media-break $br-xlg {
  .flex-row-sidebar-split {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

/* Flex Items */
.flex-split-50 {
  width: 100%;
  padding: 0 $spacing-1;
  margin-top: 30px;
}
.flex-footer {
  padding: 0 25px 20px;
  box-sizing: content-box;
  width: 240px;
}

.flex-footer:first-of-type {
  text-align: center;
}

@mixin media-break $br-md {
  .flex-footer:first-of-type {
    width: 100%;
  }
}

@mixin media-break $br-lg {
  .flex-ss-left {
    flex: 1 1 auto;
    margin-right: 50px;
  }
  .flex-ss-right {
    flex: 1 1 auto;
    width: 430px;
    min-width: 430px;
  }
}

@mixin media-break $br-xlg {
  .flex-split-row {
    display: flex;
    flex-wrap: wrap;
    -ms-flex-pack: felx-start;
  }
  .flex-split-50 {
    box-sizing: border-box;
    flex: 0 1 auto;
    width: 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .green-block,
    .red-block {
        flex: auto 1 0;
    }
  }

  /*.flex-split-50 {
    padding: $spacing-1;
    width: 50%;
    max-width: 50%;
    align-self: stretch;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: stretch;
    margin: 0;
  }
  */
  .flex-footer:first-of-type {
    width: 240px;
  }
}


/* fallbacks */
/*.no-flexbox:not(.flexboxtweener) {
  .main-content {
    @mixin float-clear;
  }
}*/
