@define-mixin link-states {
    &:hover, &:focus, &:active {
        @mixin-content;
    }
}

@define-mixin plain-list {
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    list-style: none !important;
  }
}
