%btn-base {
    display: inline-block;
    border: none;
    background: none;
    border-radius: 0;
    transition: background 100ms ease-out;
    padding: 12px 15px;

    text-decoration: none;
    line-height: 1.2;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.4rem;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    &:hover, &:focus, &:active {
        text-decoration: none;
        color: #fff;
    }
}

%inky-btn-bg {
  background: $inky;
  @mixin link-states {
    background: $inky-light;
  }
}

%green-btn-bg {
  background: $green;
  @mixin link-states {
    background: $green-light;
  }
}

.green-btn,
.form-submit {
  @extend %btn-base;
  @extend %green-btn-bg;
}

.btn-map {
  @extend %green-btn-bg;
  @extend %btn-base;
  font-family: $secondary-font;
  padding: 14px 30px 14px 14px;
}

.btn-map_text {
  display: inline-block;
  padding-left: resolve(20px + 20px); /* spacing plus icon size */
  position: relative;
  &:after {
    @mixin pseudo-absolute;
    @extend .svg-map-pin-dims;
    @extend .svg-map-pin;
    left: 0;
    top: 50%;
    transform: translateY(-50%)
  }
}

.btn-arrow {
  display: block;
  font-weight: 700;
  @extend %btn-base;
  @extend %inky-btn-bg;
  position: relative;
  font-family: $secondary-font;
  font-size: 1.6rem;
  padding: 15px 15px 15px 20px;
  @mixin media-break $br-xsm-1 {
    padding: 22px 35px 22px 80px;
  }
}

.btn-arrow_text {
  display: inline-block;
  padding-right: resolve(44px + 36px); /* spacing plus icon size */
  position: relative;
  &:after {
    @mixin pseudo-absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @extend .svg-chevrons;
    @extend .svg-chevrons-dims;
  }
}
