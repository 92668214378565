
.branch-block {
  border: $light-border;
  padding: $sm-block-spc;
  width: 100%;
}

.branch_item {
  background: #DDD;
  background: -webkit-linear-gradient(#DDD, #FFF);
  background: linear-gradient(#DDD, #FFF);
  border: $light-border;
  padding: 15px 12px;
  position: relative;
}

.branch_title {
  text-transform: uppercase;
  border-bottom: 3px solid $red;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.branch_address {
  margin-bottom: 15px;
}

.branch_btn {
  display: block;
}

/* below x-lg */
@mixin media-break-max $br-xlg-max {
  .branch-block {
    margin-top: $spacing-2;
    margin-bottom: $spacing-2;
  }
}

/* below x-small */
@mixin media-break-max $br-xsm-1-max {
  .branch_half {
    width: 100%;
    margin-top: 15px;
  }
}

/* above x-small */
@mixin media-break $br-xsm-1 {
  .branch_half {
    width: 50%;
    max-width: 50%;
    display: flex;
    /**/flex: 0 1 auto;
    /**/flex-direction: column;
    flex-wrap: nowrap;
    align-self: stretch;
    align-items: stretch;
    margin-top: 25px;
}
  .branch_half:first-of-type {
    padding-right: 3px;
  }
  .branch_half:last-of-type {
    padding-left: 3px;
  }
  .branch_item {
    min-height: 280px;
    padding-bottom: 40px;
  }
  .branch_btn {
    position: absolute;
    bottom: 20px;
    left: $sm-block-spc;
    right: $sm-block-spc;
  }
}
