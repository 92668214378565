.main-nav {
  ul {
    @mixin plain-list;
  }

  .nav_item {
    a {
      color: #fff;
      text-transform: uppercase;
      font-size: 1.8rem;
      @mixin link-states {
        color: #fff;
        background: #000;
        text-decoration: none;
        .nav_text {
          border-bottom: 2px solid #fff;
        }
      }
    }
  }

  .nav_text {
    border-bottom: 2px solid transparent;
  }
}

/* mobile only */
@mixin media-break-max $br-md-2-max {
  .main-nav {
    max-height: 0;
    overflow: hidden;
    transition: max-height 350ms ease-out, margin 150ms ease-out 100ms;

    &.is-active {
      /*max-height: 32rem;*/
      max-height: 37rem;
      margin-bottom: $spacing-1;
    }

    .nav_item {
      a {
        padding: $spacing-1;
        display: block;
      }
    }
  }

  .m-nav-toggle {
    min-width: 13.5rem; /* consistant size through text change */
    margin: $spacing-1;
    cursor: pointer;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    text-transform: uppercase;
    line-height: 1;
    padding: 10px $spacing-1 10px resolve($spacing-1 + $btn-line-width + 10px);
    position: relative;
    transition: background 150ms ease;
    .lines {
      position: absolute;
      display: block;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    .line {
      @mixin basic-btn-line;
    }
    &:focus, &:active {
      outline: none;
    }
    &.is-active {
      background: #fff;
      color: #000;
      .line {
        background: #000;
      }
      .line-one {
        transform: rotate(45deg) translate(8px, 4px);
      }
      .line-two {
        opacity: 0;
      }
      .line-three {
        transform: rotate(-45deg) translate(7px, -4px);
      }
    }
  }
}

/* destkop only */
@mixin media-break $br-md-2 {
  .m-nav-toggle {
    display: none;
  }

  .main-nav {
    ul {
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
    }

    .nav_item {
      display: flex;
      text-align: center;
      flex: 1 1 auto;
      max-width: 25%;
      a {
        padding: $spacing-1 0;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
