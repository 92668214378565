.visually-hidden {
    @mixin visually-hidden;
}

.plain-list {
  @mixin plain-list;
}

.align-center {
    text-align: center;
}

.img-right {
  float: right;
  margin-left: $spacing-1;
  margin-top: $spacing-1;
  margin-bottom: $spacing-1;
}

.img-left {
  float: left;
  margin-right: $spacing-1;
  margin-top: $spacing-1;
  margin-bottom: $spacing-1;
}
