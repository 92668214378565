%simple-block {
  padding: $spacing-2 $spacing-2 50px;
  h2 {
    margin-top: 0;
  }
}

.green-block {
  @extend %simple-block;
  @mixin font-smoothing;
  color: #fff;
  background: $green;
}

.red-block {
  @extend %simple-block;
  @mixin font-smoothing;
  color: #fff;
  background: $red;
}
