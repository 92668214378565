.header-banner {
  @mixin font-smoothing;
  background: url(../img/header-wave.jpg) no-repeat #494949;
  text-align: right;
  padding: 13px 20px 20px 0;
  @mixin media-break $br-xsm {
    padding: 26px 72px 40px 0;
  }
}

.hb_title {
  font-size: 2rem;
  font-style: italic;
  color: #fff;
  font-weight: bold;
  font-family: $main-font;
  margin-top: 0;
}
