.photo-callout {
  @extend %region-spacing;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.pc_textbox {
  background: rgba(22,22,33,0.8);
}

.pc_title {
  font-weight: 700;
  font-family: $secondary-font;
  text-transform: uppercase;
}

.pc_title,
.pc_text {
  color: #fff;
  text-align: center;
  @mixin font-smoothing;
  max-width: $max-width-sm;
  margin: 0 auto;
}

@mixin media-break-max $br-md-max {
  .photo-callout {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  .pc_textbox {
    padding: 10px 10px ;
  }
  .pc_title{
    font-size: 3.3rem;
    margin-bottom: 15px;
  }
  .pc_text {
    font-size: 1.8rem;
    line-height: 1.5;
  }
}

@mixin media-break $br-md {
  .photo-callout {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  .pc_textbox {
    padding: 25px 25px 40px;
  }
  .pc_title{
    font-size: 4.1rem;
    margin-bottom: 25px;
  }
  .pc_text {
    font-size: 2rem;
    line-height: 1.7;
  }
}
