
*, *:after, *:before {
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    box-sizing: border-box;
}

body {
    font-size: 1.8rem;
    line-height: 1.6;
    background: #171720;
    color: $text-color;
    font-family: $main-font;
}

img,
svg {
    max-width: 100%;
}
