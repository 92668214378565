.svg-chevron-left {
	background: url("../../img/sprites.svg") 0 100% no-repeat;
}

.svg-chevron-left-dims {
	width: 20px;
	height: 30px;
}

.svg-chevron-right {
	background: url("../../img/sprites.svg") 38.46153846153846% 100% no-repeat;
}

.svg-chevron-right-dims {
	width: 20px;
	height: 30px;
}

.svg-chevrons {
	background: url("../../img/sprites.svg") 0 0 no-repeat;
}

.svg-chevrons-dims {
	width: 44px;
	height: 41px;
}

.svg-map-pin {
	background: url("../../img/sprites.svg") 100% 0 no-repeat;
}

.svg-map-pin-dims {
	width: 28px;
	height: 40px;
}

