.img-desc-block {
  background: $lightest-gray;
  margin-top: $spacing-2;
}

.id_img {
  img {
    display: block;
    width: 100%;
  }
}

.id_body {
  font-size: 1.7rem;
  font-style: italic;
}

/* above desktop */
@mixin media-break $br-xlg {
  .img-desc-block {
    margin-top: 0;
  }
}

/* between mobile and desktop (img & text are parallell) */
@media (min-width: $br-xsm) and (max-width: $br-xlg-max) {
  .img-desc-block {
    display: flex;
  }

  .id_img {
    flex: 1 1 auto;
    text-align: center;
  }

  .id_body {
    flex: 1 1 auto;
    padding: $spacing-1 $spacing-1 $spacing-1 30px;
  }
}

/* below mobile, above desktop (img & text are stacked) */
@media (max-width: $br-xsm-max), (min-width: $br-xlg) {
  .id_body {
    padding: 8px 10px;
  }
}
