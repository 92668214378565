$text-space: 15px;

p {
    margin: $text-space 0;
    &:last-of-type{
      margin-bottom: 0;
    }
    &:first-of-type{
      margin-top: 0;
    }
}

hr {
  border: none;
  margin-top: $spacing-2;
  margin-bottom: $spacing-2;
  border-top: 1px dashed $green;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  font-weight: 400;
  margin: $spacing-1 auto;
}

/* add space to headings that come directly after p's */
p {
  + h1, + h2, + h3, + h4, + h5, + h6 {
    margin-top: $spacing-2;
  }
}

h1 {
  font-size: 4.5rem;
}
h2 {
  font-size: 3.2rem;
}
h3 {
  font-size: 2.6rem;
}
h4 {
  font-size: 2.2rem;
}
h5,
h6 {
  font-size: 2rem;
}

.page-title {
  text-transform: uppercase;
  margin-bottom: 0;
  padding-bottom: $spacing-2;
}

.subtitle {
  border-top: 4px solid $red;
  padding-top: $spacing-2;
  margin-top: $spacing-2;
}

.intro-text {
  font-size: 2rem;
  margin-bottom: $spacing-2;
}

.smaller-text {
  font-size: 1.6rem;
}

.bigger-text {
  font-size: 2rem;
}

.sm-gray-title {
  font-size: 1.8rem;
  color: $text-gray;
}

.sm-gray-address {
  font-style: normal;
  color: $text-gray;
  font-size: 1.6rem;
}
