.hero {
  @mixin float-clear;
  background-color: #fff;
}

.hero_logo {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.hero_main {
  overflow: hidden;
}

.hero_main--bg-img {
  background: center;
}

/* mobile */
@mixin media-break-max $br-lg-max {
  .hero_side {
    background: url(../img/mesh-bg.jpg) no-repeat;
    background-size: cover;
    padding: 20px;
  }

  .hero_main--bg-img {
    height: 230px;
  }
}

/* desktop */
@mixin media-break $br-lg {
  $hero_side-w: 343px;

  .hero {
    background-image: url(../img/mesh-bg.jpg);
    background-repeat: no-repeat;
    background-position: top left;
    display: flex;
    align-items: stretch;
  }

  .hero_side {
    padding: 40px 20px 95px; /* extra bottom padding to keep text from being hidden */
    background: none;
    width: 343px;
    flex: 0 0 auto;
  }

  .hero_main {
    flex: 1 1 auto;
  }

  .hero_main--bg-img {
    background-repeat: no-repeat;
    background-size: cover;
  }
}
