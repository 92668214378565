a {
    color: $red;
    text-decoration: none;
    &:hover, &:focus, &:active {
        color: $red-dark;
        text-decoration: underline;
    }
}

.link-list-columns {
  ul {
    @mixin plain-list;
    margin: $spacing-2 -$spacing-1;
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
  }
  li {
    flex: 0 1 50%;
  }
  a {
    font-size: 2rem;
    padding: 11px $spacing-1;
    display: inline-block;
  }
  @mixin media-break $br-md {
    li {
      flex-basis: resolve(100/3)%;
    }
  }
}

.skip-link {
    &:focus {
        height: auto;
        width: 100%;
        left: 0;
        top: 0;
        display: block;
        overflow: auto;
        clip: auto;
        z-index: 50;
        text-align: center;
        color: $red;
        text-decoration: none;
        padding: 10px;
        background-color: $light-gray;
        font-weight: 700;
        outline: none;
    }
}
