/* modifiers */
%region-spacing {
  padding-left: 20px;
  padding-right: 20px;
  @mixin media-break $br-md {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.white-region {
  @extend %region-spacing;
  background: #fff;
  overflow: hidden;
}

/* specific */
.main-nav-region {
  background: #222;
  overflow: hidden;
}
