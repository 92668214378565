.footer {
  background: $gray;
  @extend %region-spacing;
}

.footer_title {
  margin-bottom: 5px;
}

.footer_address {
  font-family: $secondary-font;
  line-height: 1.4;
}

@mixin media-break-max $br-md-max {
  .footer {
    text-align: center;
    border-top: 30px solid #fff;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .footer--home {
    border: none;
  }
}

@mixin media-break $br-md {
  .footer {
    border-top: 75px solid #fff;
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .footer--home {
    border: none;
  }
}

@mixin media-break $br-lg {
  .footer {
    padding-bottom: 80px;
  }
}
