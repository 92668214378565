$field-spacing: 8px;

.webform-client-form {
  border: $light-border;
  padding: $sm-block-spc;
  margin: 30px 0;
  > div {
    background: #DDD;
    border: 1px solid #ececec;
    padding: 25px $field-spacing 0;
    overflow: hidden;
  }
}

/* Fields, Labels, Containers */
.form-text[type="text"],
.form-textarea {
  border-radius: 0;
}

.form-item,
.form-actions {
  padding: 0 $field-spacing;
}

.form-item {
  margin: 0 0 25px;
  padding: 0 $field-spacing;
  label {
    margin-bottom: 10px;
  }
}

.form-text,
.form-textarea-wrapper {
  width: 100%;
  max-width: 100%;
}

.grippie {
  background: #9C9C9C;
  border: none;
}

.form-required {
  color: #000;
}

.form-text,
.form-textarea {
  padding: 20px 15px;
  transition: border 150ms ease-out;
}

/* webform only */
.webform-component {
  .form-text[type="text"],
  .form-textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .form-text,
  .form-textarea {
    border: 1px solid #fff;
    &:focus, &:active {
      outline: none;
      border-color: $green;
    }
  }
}



@mixin media-break-max $br-xlg-max {
  .form-text {
    width: 500px;
  }
  .form-textarea-wrapper {
    width: 650px;
  }
}

@mixin media-break $br-xlg {
  .webform-component-textfield,
  .webform-component-email {
    width: 50%;
    float: left;
  }
}
